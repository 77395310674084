/* BUTTONS BASE START */

.btn--link,
.btn--text,


.btn--dashed-l,
.btn--dashed,
.btn--dashed-s,

.btn--default-l,
.btn--default,
.btn--default-s,

.btn--primary-s,
.btn--primary-l,
.btn--primary {
    transition: all 0.2s cubic-bezier(0.2, 0, 0, 1), transform 0.2s cubic-bezier(0.2, 0, 0, 1);
}

.btn--link:active:not([disabled]),
.btn--text:active:not([disabled]),


.btn--dashed-l:active:not([disabled]),
.btn--dashed:active:not([disabled]),
.btn--dashed-s:active:not([disabled]),

.btn--default-l:active:not([disabled]),
.btn--default:active:not([disabled]),
.btn--default-s:active:not([disabled]),

.btn--primary-l:active:not([disabled]),
.btn--primary:active:not([disabled]),
.btn--primary-s:active:not([disabled]) {
    /* transform: scale(0.96); */
}

.btn--dashed-l:disabled,
.btn--dashed:disabled,
.btn--dashed-s:disabled,

.btn--default-l:disabled,
.btn--default:disabled,
.btn--default-s:disabled,

.btn--primary-l:disabled,
.btn--primary:disabled,
.btn--primary-s:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #D9D9D9;
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: none;
}


.btn--link,
.btn--text,
.btn--dashed,
.btn--default,
.btn--primary {
    font-size: 14px;
    line-height: 22px;
    padding: 5px 15px;
}

.btn--link-l,
.btn--text-l,
.btn--dashed-l,
.btn--default-l,
.btn--primary-l {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 15px;
}

.btn--link-s,
.btn--text-s,
.btn--dashed-s,
.btn--default-s,
.btn--primary-s {
    font-size: 14px;
    line-height: 22px;
    padding: 1px 7px;
}

.btn--link,
.btn--text,
.btn--link-l,
.btn--text-l,
.btn--link-s,
.btn--text-s,

.btn--dashed-l,
.btn--dashed,
.btn--dashed-s,

.btn--default-l,
.btn--default,
.btn--default-s,

.btn--primary-l,
.btn--primary,
.btn--primary-s {
    font-style: normal;
    font-weight: 500;
    border-radius: 24px;
}

/* BUTTONS BASE END */


/* PRIMARY BUTTON CSS START */

.btn--primary-s,
.btn--primary-l,
.btn--primary {
    box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.10);
    color: white;
    background-color: #2A7DE6;
    border: 1px solid #2A7DE6;
}

.btn--primary-s:hover:not([disabled]),
.btn--primary-l:hover:not([disabled]),
.btn--primary:hover:not([disabled]) {
    background-color: #3D98F1;
    border: 1px solid #3D98F1;
}

.btn--primary-s:active:not([disabled]),
.btn--primary-l:active:not([disabled]),
.btn--primary:active:not([disabled]) {
    background-color: #2266D3;
    border: 1px solid #2266D3;
}

/* PRIMARY BUTTON CSS END */


/* DEFAULT BUTTON CSS START */
.btn--default-s,
.btn--default,
.btn--default-l {
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    color: rgba(0, 0, 0, 0.88);
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.88);
}

.btn--default-l:hover:not([disabled]),
.btn--default:hover:not([disabled]),
.btn--default-s:hover:not([disabled]) {
    color: #3D98F1;
    border: 1px solid #3D98F1;
}

.btn--default-s:active:not([disabled]),
.btn--default:active:not([disabled]),
.btn--default-l:active:not([disabled]) {
    color: #2266D3;
    border: 1px solid #2266D3;
}

/* DEFAULT BUTTON CSS END */

/* DASHED BUTTON CSS START */
.btn--dashed-s,
.btn--dashed,
.btn--dashed-l {
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    color: rgba(0, 0, 0, 0.88);
    background-color: #FFF;
    border: 1px dashed #D9D9D9;
}

.btn--dashed-l:hover:not([disabled]),
.btn--dashed:hover:not([disabled]),
.btn--dashed-s:hover:not([disabled]) {
    color: #3D98F1;
    border: 1px dashed #3D98F1;
}

.btn--dashed-s:active:not([disabled]),
.btn--dashed:active:not([disabled]),
.btn--dashed-l:active:not([disabled]) {
    color: #2266D3;
    border: 1px dashed #2266D3;
}

/* DASHED BUTTON CSS END */