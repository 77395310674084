.findit-checkbox-container {
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.findit-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.findit-checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    border: 2px solid #D9D9D9;
    /* background-color: var(--grayscale-9); */
}

/* On mouse-over, add a grey background color
.findit-checkbox-container:hover input~.findit-checkmark {
    background-color: var(--grayscale-8);
}
*/

/* When the checkbox is checked, add a blue background */
.findit-checkbox-container input:checked~.findit-checkmark {
    background-color: var(--blue-6);
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.findit-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.findit-checkbox-container input:checked~.findit-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.findit-checkbox-container .findit-checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}