/* You can add global styles to this file, and also import other style files */
* {
	font-family: 'Plus Jakarta Sans', sans-serif !important;
	font-display: swap;
}

.map-container {
	height: 100% !important;
	width: 100% !important;
	position: relative;
	transition: all .4s ease-in-out;
}

mark {
	color: black;
}

#credential_picker_container {
	right: 16px !important;
	top: 55px !important;
}

.backdropBackground {
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(20px);
}



.findit-outline-dark-btn,
.findit-outline-btn,
.findit-close-btn,
.findit-action-btn,
.findit-btn-bounce-effect {
	/* transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1), transform 0.1s cubic-bezier(0.2, 0, 0, 1); */
	transition: all 0.2s cubic-bezier(0.2, 0, 0, 1), transform 0.1s cubic-bezier(0.2, 0, 0, 1);
}

.findit-outline-dark-btn,
.findit-outline-btn,
.findit-action-btn {
	border-radius: 4px;
	border: none;
	padding: 11px 12px;
}

.findit-outline-btn {
	color: var(--primary-color-1);
	border: 1px solid var(--primary-color-1);
	background-color: white;
}

.findit-outline-dark-btn {
	color: var(--grayscale-2);
	border: 1px solid var(--grayscale-9);
	background-color: white;

}

.findit-action-btn {
	background-color: var(--primary-color-1);
	color: white !important;
}

.findit-outline-dark-btn:disabled,
.findit-outline-btn:disabled,
.findit-action-btn:disabled {
	opacity: 0.5;
}


.findit-close-btn {
	background-color: white;
	border: 1px solid var(--grayscale-9);
	border-radius: 100%;
	padding: 0;
	margin: 0;
	height: 34px;
	width: 34px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0, 0, 0, 0.18);
}


.findit-outline-dark-btn:active,
.findit-outline-btn:active,
.findit-btn-bounce-effect:active,
.findit-close-btn:active,
.findit-action-btn:active {
	transform: scale(0.96);
}

.findit-in-hvr:hover {
	border-color: var(--primary-color-1);
	border-width: 1px;
}

.border-radius-none .mat-mdc-dialog-container .mdc-dialog__surface {
	border-radius: 0;
	background-color: black;
}

.app-full-bleed-dialog .mat-dialog-container {
	padding: 0;
}

.mat-overflow-scroll {
	overflow: scroll !important;
}

.stripe-error-font {
	color: #df1b41 !important;
	font-size: 0.93rem !important;
	font-weight: normal !important;
	/* text-align: center; */
}

.no-mat-horizontal-padding .mat-horizontal-content-container {
	padding: 0 !important;
}

.no-border-radius .mat-mdc-dialog-container .mdc-dialog__surface {
	border-radius: 0px !important;
}

@media screen and (max-width: 768px) {

	.mobile-bottom-transition .mat-mdc-dialog-container .mdc-dialog__surface {
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
	}

	.mobile-bottom-transition {
		position: absolute !important;
		bottom: 0 !important;
		max-width: 100% !important;
	}

	.mobile-bottom-transition.selected-listing .mat-mdc-dialog-container .mdc-dialog__surface {
		border-radius: 0px !important;
	}

	.mobile-bottom-transition.selected-listing {
		width: 100% !important;
		height: 100% !important;
		max-height: 100% !important;
	}

	.edit_dialog_scroll_mobile {
		overflow-y: scroll;
	}
}

.dot-yellow,
.dot-blue {
	height: 9px;
	width: 9px;
	vertical-align: inherit;
	border: 1px solid white;
	border-radius: 50%;
	display: inline-block;
	box-shadow: 0px 1.125px 2.25px rgb(0 0 0 / 12%);
}

.dot-yellow {
	background-color: #ffbd2e;
}

.dot-blue {
	background-color: var(--blue-6);
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

.backdropBackground-selected-property {
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(1px);
}

html,
body {
	height: 100%;
	touch-action: manipulation;
	overscroll-behavior: none;
}

html {
	margin: 0;
	--blue-1: #EFF8FF;
	--blue-2: #C1E2FC;
	--blue-3: #91CAFF;
	--blue-4: #64B7F6;
	--blue-5: #3D98F1;
	--blue-6: #2A7DE6;
	--blue-7: #2266D3;
	--blue-8: #2253AB;
	--blue-9: #214887;
	--blue-10: #192D52;

	--gold-1: #FFFBE6;
	--gold-2: #FFF1B8;
	--gold-3: #FFE58F;
	--gold-4: #FFD666;
	--gold-5: #FFC53D;
	--gold-6: #FAAD14;
	--gold-7: #D48806;
	--gold-8: #AD6800;
	--gold-9: #874D00;
	--gold-10: #613400;

	--jade-1: #F6FFED;
	--jade-2: #D9F7BE;
	--jade-3: #B7EB8F;
	--jade-4: #95DE64;
	--jade-5: #73D13D;
	--jade-6: #52C41A;
	--jade-7: #389E0D;
	--jade-8: #237804;
	--jade-9: #135200;
	--jade-10: #092B00;

	--green-1: #F6FFED;
	--green-2: #D9F7BE;
	--green-3: #B7EB8F;
	--green-4: #95DE64;
	--green-5: #73D13D;
	--green-6: #52C41A;
	--green-7: #389E0D;
	--green-8: #237804;
	--green-9: #135200;
	--green-10: #092B00;

	--purple-1: #F9F0FF;
	--purple-2: #EFDBFF;
	--purple-3: #D3ADF7;
	--purple-4: #B37FEB;
	--purple-5: #9254DE;
	--purple-6: #722ED1;
	--purple-7: #531DAB;
	--purple-8: #391085;
	--purple-9: #22075E;
	--purple-10: #120338;


	--yellow-1: #FEFFE6;
	--yellow-2: #FFFFB8;
	--yellow-3: #FFFB8F;
	--yellow-4: #FFF566;
	--yellow-5: #FFEC3D;
	--yellow-6: #FADB14;
	--yellow-7: #D4B106;
	--yellow-8: #AD8B00;
	--yellow-9: #876800;
	--yellow-10: #614700;


	--size-1: 64px;
	--size-2: 52px;
	--size-3: 30px;
	--size-4: 24px;
	--size-5: 20px;
	--size-6: 18px;
	--size-7: 16px;
	--size-8: 14px;
	--size-9: 12px;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
	height: 64px;
}

@media (max-width: 599px) {

	.mat-toolbar-row,
	.mat-toolbar-single-row {
		height: 56px;
	}
}

@media screen and (min-width: 1241px) {
	.listing-card-responsiveness {
		max-width: 289.67px;
	}
}

.side-nav-open {
	overflow: hidden;
}

.price-dot,
.tour-dot {
	text-align: center;
	white-space: nowrap;
	pointer-events: auto;

	border-radius: 20px;
	background-color: #FFF;
	padding: 8px 12px;

	font-size: 14px;
	line-height: 22px;
	color: #27292b;
	font-weight: 600;


	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}

.price-dot.sponsored {
	background-color: var(--blue-6);
	color: #FFF;
	z-index: 999;
	display: flex;
	gap: 4px;
	align-items: center;
}

.tour-dot {
	background-color: var(--blue-6);
	display: inherit;
	color: white;
	border: none;
	top: -45px;
	font-weight: 500;
	box-shadow: none;
}

.map-dot:hover {
	background-color: var(--blue-8) !important;
}

.map-dot {
	box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.05),
		0px 3px 6px rgba(0, 0, 0, 0.12),
		0px 6px 16px rgba(0, 0, 0, 0.08);

	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 2px solid #FFF;
	pointer-events: auto;

	position: absolute;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.map-dot.sponsored {
	width: 20px;
	height: 20px;
	z-index: 99;
	display: flex;
	align-items: center;
	gap: 4px;
}

.price-div-container {
	display: flex;
	align-items: center;
	flex-flow: column;
	position: absolute;
	top: -51px;
}

/* FIND IT OFFICIAL */
.--text-base,
.--text-sm,
.--text-lg,
.--text-xl,
.--text-base-b,
.--text-sm-b,
.--text-lg-b,
.--text-xl-b,
.--heading-1,
.--heading-2,
.--heading-3,
.--heading-4,
.--heading-5,
.--heading-6 {
	color: rgba(0, 0, 0, 0.88);
	font-family: Plus Jakarta Sans;
	font-style: normal;
}

.--heading-1,
.--heading-2,
.--heading-3 {
	font-weight: 600;
}

.--heading-4,
.--heading-5,
.--heading-6 {
	font-weight: 500;
}

.--heading-1 {
	font-size: var(--size-1);
	line-height: 77px;
	letter-spacing: -1.28px;
}

.--heading-2 {
	font-size: var(--size-2);
	line-height: 67.2px;
	letter-spacing: -1.04px;
}

.--heading-3 {
	font-size: var(--size-3);
	line-height: 36px;
	letter-spacing: -0.6px;
}

.--heading-4 {
	font-size: var(--size-4);
	line-height: 32px;
	letter-spacing: -0.48px;
}

.--heading-5-v2 {
	font-size: var(--size-5);
	line-height: 28px;
	letter-spacing: -0.4px;
}

.--heading-5 {
	font-size: var(--size-7);
	line-height: 24px;
}



.--text-base-b,
.--text-base {
	font-size: var(--size-8);
	line-height: 22px;
}

.--text-sm-b,
.--text-sm {
	font-size: var(--size-9);
	line-height: 20px;
}

.--text-lg-b,
.--text-lg {
	font-size: var(--size-7);
	line-height: 20px;
}


.--text-xl-b,
.--text-xl {
	font-size: var(--size-5);
	line-height: 28px;
}

.--text-base-b,
.--text-sm-b,
.--text-lg-b,
.--text-xl-b {
	font-weight: 500;
}


select,
input,
textarea {
	border: 1px solid var(--grayscale-9);
	border-radius: 4px;
	outline: none;
	font-size: 16px;
	padding: 10px;
	margin-bottom: 16px;
	width: 100%;
	transition: all 150ms ease-in;
	appearance: none;
}


.hover-preview {
	background-color: #fff;
	border: 2px solid #FFF;
	overflow: hidden;
	border-radius: 12px;
	position: absolute;
	z-index: 999;
	right: -84px;
	top: 16px;
	display: flex;
	gap: 12px;
	box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
	width: max-content;
}

.hover-preview .sponsored-badge {
	position: absolute;
	top: 0;
	left: 8px;
	background: var(--blue-6);
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	padding: 6px 4px;
}

.hover-preview.sponsored {
	border: 2px solid var(--blue-6);
}

.hover-preview-info {
	padding: 4px 6px 4px 0px;
}

.hover-preview img {
	width: 84px;
	height: 84px;
	/* max-height: 86px; */
	/* border-radius: 8px; */
	object-fit: cover;
}

.hover-preview .--text-lg-b {
	font-weight: 700;
	margin-bottom: 4px;
	line-height: 16px;
}

.hover-preview .--text-sm {
	color: #00000059;
	line-height: 14px;
}